import React, { useState, useEffect } from 'react'
import { useSubheader } from '../../../_metronic/layout'
import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { baseURL, imageURL } from '../../service/API.js'
import Switch from '@material-ui/core/Switch'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import CropperImage from './CropperImage'
import './component.css'
import { useSelector } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "@material-ui/core/Modal";

export const EditUsersManagement = props => {
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()
  suhbeader.setTitle('Edit Users Management')
  const history = useHistory()
  const [category_id, setCategory_id] = useState()
  const [id, setId] = useState()

  const [first_name, setFirst_name] = useState()
  const [last_name, setLast_name] = useState()
  const [username, setUsername] = useState()
  const [email, setEmail] = useState()
  const [external_id, setExternal_id] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [phone_number, setPhonenumber] = useState()
  const [state, setState] = useState(true)
  const [state2, setState2] = useState(true)
  const [openCheck, setOpenCheck] = useState(false)

  const AlertError = message => {
    Swal.fire('Users Management!', message, 'error')
  }

  useEffect(() => {
    console.log(props.location.state.row)
    setId(props.location.state.row.id)
    setFirst_name(props.location.state.row.first_name)
    setLast_name(props.location.state.row.last_name)
    setUsername(props.location.state.row.username)
    setEmail(props.location.state.row.email)
    setExternal_id(props.location.state.row.external_id)
    setPhonenumber(props.location.state.row.phone_number)
    setState(props.location.state.row.enable)
    setState2(props.location.state.row.is_admin)
  }, [])

  const handleChangeSwitch = () => {
    setState(!state)
  }

  const handleChangeSwitch2 = () => {
    setState2(!state2)
  }

  const onSubmit = async data => {
    if (first_name === undefined || first_name === '') {
      return AlertError('please insert first name.')
    }

    if (last_name === undefined || last_name === '') {
      return AlertError('please insert last name.')
    }

    if (email === undefined || email === '') {
      return AlertError('please insert item name.')
    }

    if (username === undefined || username === '') {
      return AlertError('please insert username')
    }

    if (phone_number === undefined || phone_number === '') {
      return AlertError('please insert phone number')
    }

    var events = {
      username: username,
      first_name: first_name,
      last_name: last_name,
      email: email,
      external_id: external_id,
      password: password,
      enabled: state,
      is_admin: state2,
      phone_number: phone_number
    }
    console.log(events)

    let apiUrls = `${baseURL}/user/${id}`
    let token = localStorage.getItem('Token')
    const resultAll = axios({
      method: 'put',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: events
    })
      .then(async res => {
        history.goBack()
      })
      .catch(err => {
        Swal.fire(
          'Edit !',
          'Your can not Edit Users Management. !! ' + err,
          'error'
        )
      })
  }

  const editPassword = async (values) => {
    console.log(values.password);
    console.log(values.newpassword);

    let data = {
      password: values.password,
      newPassword: values.newpassword,
    };

    console.log(data);
    if (data.password && data.newPassword) {
      axios
        .put(
          `${baseURL}/user/${props.location.state.row.id}/password`,
          data
        )
        .then((res) => {
          console.log(res.data.masager);
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          alert("Password don't match");
        });
    }
  };

  const handleClose = () => {
    setOpenCheck(false);
  };

  const handleSubmitPassword = async (values, { setSubmitting }) => {
    await setSubmitting(false);
    await editPassword(values);
    return;
  };


  return (
    <>
      <Card>
        <CardHeader title="Edit Users Management"></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Username :{' '}
            </p>
            <input
              name="username"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={username}
              onChange={username => setUsername(username.target.value)}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              First name :{' '}
            </p>
            <input
              name="first_name"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={first_name}
              onChange={first_name => setFirst_name(first_name.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Last name:{' '}
            </p>
            <input
              name="last_name"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={last_name}
              onChange={last_name => setLast_name(last_name.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Email :{' '}
            </p>
            <input
              name="email"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={email}
              onChange={email => setEmail(email.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p
              style={{
                marginTop: '15px',
                float: 'left',
                width: '20%'
              }}
            >
              Password :{' '}
            </p>

            <a
              style={{
                textDecoration: '#007BFF underline',
                color: '#007BFF'
              }}
              data-toggle="modal"
              data-target="#modal-EditPassword"
              onClick={() => setOpenCheck(true)}
            >
              Change Password
            </a>
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Phone number:{' '}
            </p>
            <input
              name="phone_number"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={phone_number}
              onChange={phone_number =>
                setPhonenumber(phone_number.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Shop ID:{' '}
            </p>
            <input
              name="external_id"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={external_id}
              onChange={external_id => setExternal_id(external_id.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p
              style={{
                marginTop: '15px',
                float: 'left',
                width: '20%'
              }}
            >
              Active:{' '}
            </p>
            <Switch
              checked={state}
              value="state"
              onChange={() => handleChangeSwitch()}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p
              style={{
                marginTop: '15px',
                float: 'left',
                width: '20%'
              }}
            >
              Admin:{' '}
            </p>
            <Switch
              checked={state2}
              value="state2"
              onChange={() => handleChangeSwitch2()}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>

          <div
            className="form-inline mt-5"
            style={{
              marginBottom: '25px'
            }}
          >
            <div
              style={{
                marginTop: '15px',
                float: 'left',
                width: '20%'
              }}
            ></div>
            <Link to="/UsersManagement">
              <button type="button" className="btn btn-secondary wbt mr-5">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              Save
            </button>
          </div>
        </CardBody>
      </Card>

      <Modal
          open={openCheck}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="justify-content-center d-flex align-items-center mt-1">
                <span style={{ fontSize: "30px" }}>Change Password</span>
              </div>
              <div className="modal-body">
                <Formik
                  initialValues={{
                    password: "",
                    newpassword: "",
                  }}
                  validate={(values) => {
                    let errors = {};

                    if (!values.password)
                      errors.password = "Password is required.";

                    if (!values.newpassword)
                      errors.newpassword = "New password is required.";

                    //check if my values have errors
                    return errors;
                  }}
                  onSubmit={handleSubmitPassword}
                  render={(formProps) => {
                    return (
                      <Form className="col">
                        <div className="row">
                          <span
                            style={{
                              fontSize: "20px",
                              float: "left",
                              width: "40%",
                            }}
                          >
                            Password :
                          </span>

                          <Field
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            style={{
                              height: "38px",
                              width: "530px",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>
                        <div className="form-inline">
                          <ErrorMessage className="colorError" name="password">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="row mt-1">
                          <span
                            style={{
                              fontSize: "20px",
                              float: "left",
                              width: "40%",
                            }}
                          >
                            New Password :{" "}
                          </span>
                          <Field
                            type="password"
                            name="newpassword"
                            className="form-control"
                            placeholder="Password"
                            style={{
                              height: "38px",
                              width: "530px",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                          />
                        </div>

                        <div className="form-inline">
                          <ErrorMessage
                            className="colorError"
                            name="newpassword"
                          >
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>

                        <div className="modal-footer">
                          <button
                            className="btn btn-danger"
                            type="submit"
                            disabled={formProps.isSubmitting}
                          >
                            Confirm
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => handleClose()}
                          >
                            Close
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>

    </>
  )
}
