
import axios from "axios";
import { baseURLLogin } from "../../../service/API";

export const LOGIN_URL = `${baseURLLogin}/auth/login`;
export const ME_URL = `${baseURLLogin}/auth/me`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.post(ME_URL);
}
