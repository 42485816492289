import React, { useState, useEffect } from 'react'
import { useSubheader } from '../../../_metronic/layout'
import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { baseURL, imageURL } from '../../service/API.js'
import Switch from '@material-ui/core/Switch'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import CropperImage from './CropperImage'
import './component.css'
import { useSelector } from 'react-redux'

export const CreateUsersManagement = () => {
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()
  suhbeader.setTitle('Create Users Management')
  const history = useHistory()
  const [category_id, setCategory_id] = useState()

  const [first_name, setFirst_name] = useState()
  const [last_name, setLast_name] = useState()
  const [username, setUsername] = useState()
  const [email, setEmail] = useState()
  const [external_id, setExternal_id] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [phone_number, setPhonenumber] = useState()
  const [state, setState] = useState(true)
  const [state2, setState2] = useState(true)

  const AlertError = message => {
    Swal.fire('Users Management!', message, 'error')
  }

  const handleChangeSwitch = () => {
    setState(!state)
  }

  const handleChangeSwitch2 = () => {
    setState2(!state2)
  }

  const onSubmit = async data => {
    if (first_name === undefined || first_name === '') {
      return AlertError('please insert first name.')
    }

    if (last_name === undefined || last_name === '') {
      return AlertError('please insert last name.')
    }

    if (email === undefined || email === '') {
      return AlertError('please insert item name.')
    }

    if (password === undefined || password === '') {
      return AlertError('please insert password.')
    }

    if (confirmPassword === undefined || confirmPassword === '') {
      return AlertError('please insert conferm password')
    }

    if (confirmPassword !== password) {
      return AlertError('Password not match')
    }

    if (username === undefined || username === '') {
      return AlertError('please insert username')
    }

    if (phone_number === undefined || phone_number === '') {
      return AlertError('please insert phone number')
    }

    var events = {
      username: username,
      first_name: first_name,
      last_name: last_name,
      email: email,
      external_id: external_id,
      password: password,
      enabled: state,
      is_admin: state2,
      phone_number: phone_number
    }
    console.log(events)

    let apiUrls = `${baseURL}/user`
    let token = localStorage.getItem('Token')
    const resultAll = axios({
      method: 'post',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: events
    })
      .then(async res => {
        history.goBack()
      })
      .catch(err => {
        Swal.fire(
          'Create !',
          'Your can not create Users Management. !! ' + err,
          'error'
        )
      })
  }

  return (
    <>
      <Card>
        <CardHeader title="Create Users Management"></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Username :{' '}
            </p>
            <input
              name="username"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={username => setUsername(username.target.value)}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              First name :{' '}
            </p>
            <input
              name="first_name"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={first_name => setFirst_name(first_name.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Last name:{' '}
            </p>
            <input
              name="last_name"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={last_name => setLast_name(last_name.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Email :{' '}
            </p>
            <input
              name="email"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={email => setEmail(email.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Password:{' '}
            </p>
            <input
              name="password"
              type="password"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={password => setPassword(password.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Confirm Password:{' '}
            </p>
            <input
              name="confirmPassword"
              type="password"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={confirmPassword =>
                setConfirmPassword(confirmPassword.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Phone number:{' '}
            </p>
            <input
              name="phone_number"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={phone_number =>
                setPhonenumber(phone_number.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Shop ID:{' '}
            </p>
            <input
              name="external_id"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={external_id => setExternal_id(external_id.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p
              style={{
                marginTop: '15px',
                float: 'left',
                width: '20%'
              }}
            >
              Active:{' '}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p
              style={{
                marginTop: '15px',
                float: 'left',
                width: '20%'
              }}
            >
              Admin:{' '}
            </p>
            <Switch
              checked={state2}
              onChange={() => handleChangeSwitch2()}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>

          <div
            className="form-inline mt-5"
            style={{
              marginBottom: '25px'
            }}
          >
            <div
              style={{
                marginTop: '15px',
                float: 'left',
                width: '20%'
              }}
            ></div>
            <Link to="/UsersManagement">
              <button type="button" className="btn btn-secondary wbt mr-5">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              Save
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
