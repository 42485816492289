import React, { useState, useEffect } from 'react'
import { useSubheader } from '../../../_metronic/layout'
import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { baseURL, imageURL } from '../../service/API.js'
import Switch from '@material-ui/core/Switch'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import CropperImage from './CropperImage'
import './component.css'
import { useSelector } from 'react-redux'

export const CreateMenuManagement = () => {
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()
  suhbeader.setTitle('Create Menu Management')
  const history = useHistory()
  const [category_id, setCategory_id] = useState()

  const [item_name, setItem_name] = useState()
  const [item_name_en, setItem_name_en] = useState()
  const [description, setDescription] = useState()
  const [description_en, setDescription_en] = useState()
  const [item_image_path, setItem_image_path] = useState()
  const [displayOrder, setDisplayOrder] = useState(0)
  const [nameImageItem_image_path, setNameImageItem_image_path] = useState()
  const [price, setPrice] = useState()
  const [state, setState] = useState(true)
  const [state2, setState2] = useState(false)
  const [category, setCategory] = useState([])

  const [stateItem_image_path, setStateItem_image_path] = useState({
    previewItem_image_path: null,
    rawItem_image_path: null,
    imageURLItem_image_path: null
  })

  const AlertError = message => {
    Swal.fire('Menu Management!', message, 'error')
  }

  useEffect(() => {
    getCategory()
  }, [])

  const getCategory = async () => {
    let apiUrls = `${baseURL}/category/select?is_delete=false&shop_id=${user.data.shop_id}`
    let token = localStorage.getItem('Token')
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(async res => {
        setCategory(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const handleChangeCategory = event => {
    setCategory_id(event.target.value)
  }

  const handleChangeItem_image_path = async e => {
    if (e.target.files.length) {
      e.preventDefault()
      let file = e.target.files[0]
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function (e) {
        var image = new Image()
        image.src = e.target.result
        image.onload = function () {
          setStateItem_image_path({
            rawItem_image_path: file,
            imageURLItem_image_path: e.target.result
          })
          e.target.value = null
          return true

        }
      }
    }
  }
  const handleImageUploadItem_image_path = async file => {
    var formdata = new FormData()
    formdata.append('image', file, URL.createObjectURL(file))
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    }

    fetch(`${baseURL}/upload/`, requestOptions)
      .then(response => response.text())
      .then(result => {
        let res = JSON.parse(result)
        console.log(res)
        setStateItem_image_path({
          previewItem_image_path: null,
          rawItem_image_path: null,
          imageURLItem_image_path: null
        })
        setItem_image_path(`${imageURL}/uploads/${res.filename}`)
        setNameImageItem_image_path(res.filename)
      })
      .catch(error => console.log('error', error))
  }

  const removeImageItem_image_path = async () => {
    setStateItem_image_path({
      previewItem_image_path: null,
      rawItem_image_path: null,
      imageURLItem_image_path: null
    })
    setItem_image_path('')
  }

  const handleCancelUploadItem_image_path = () => {
    setStateItem_image_path({
      previewItem_image_path: null,
      rawItem_image_path: null,
      imageURLItem_image_path: null
    })
  }

  const handleChangeSwitch = () => {
    setState(!state)
  }

  const handleChangeSwitch2 = () => {
    setState2(!state)
  }

  const onSubmit = async data => {
    if (category_id === undefined || category_id === '') {
      return AlertError('please select Category.')
    }

    if (item_name === undefined || item_name === '') {
      return AlertError('please insert item name.')
    }

    if (item_name_en === undefined || item_name_en === '') {
      return AlertError('please insert item name en.')
    }

    if (displayOrder === undefined || displayOrder === '' || isNaN(displayOrder) || Number(displayOrder) === 0) {
      return AlertError('please insert display order.')
    }

    if (price === undefined || price === '') {
      return AlertError('please insert price.')
    }

    var events = {
      shop_id: user.data.shop_id,
      igroup: category_id,
      name_th: item_name,
      name_en: item_name_en,
      description_th: description,
      description_en: description_en,
      item_image: item_image_path,
      display_order: displayOrder === 0 ? 0 : displayOrder,
      price: Number(price),
      enabled: state,
      is_not_available: state2
    }
    console.log(events)

    let apiUrls = `${baseURL}/menuManagement`
    let token = localStorage.getItem('Token')
    const resultAll = axios({
      method: 'post',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: events
    })
      .then(async res => {
        history.goBack()
      })
      .catch(err => {
        Swal.fire(
          'Create !',
          'Your can not create Menu Management. !! ' + err,
          'error'
        )
      })
  }

  return (
    <>
      <Card>
        <CardHeader title="Create Menu Management"></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Category:{' '}
            </p>
            <select
              as="select"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={category_id}
              onChange={handleChangeCategory}
            >
              <option value="">Please select</option>
              {category &&
                category.map((result, index) => {
                  return (
                    <option key={index} value={result.id}>
                      {result.group_name}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Price (Baht) :{' '}
            </p>
            <input
              name="price"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={price => setPrice(price.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p
              style={{
                marginTop: '15px',
                float: 'left',
                width: '20%'
              }}
            >
              Enabled:{' '}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Display Order:{' '}
            </p>
            <input
              name="displayOrder"
              type="number"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={displayOrder}
              onChange={displayOrder =>
                setDisplayOrder(displayOrder.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p
              style={{
                marginTop: '15px',
                float: 'left',
                width: '20%'
              }}
            >
              Out of stock:{' '}
            </p>
            <Switch
              checked={state2}
              onChange={() => handleChangeSwitch2()}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Name (TH):{' '}
            </p>
            <input
              name="item_name"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={item_name => setItem_name(item_name.target.value)}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Name (EN):{' '}
            </p>
            <input
              name="item_name_en"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={item_name_en =>
                setItem_name_en(item_name_en.target.value)
              }
            />
          </div>
          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Description (TH):{' '}
            </p>
            <input
              name="description"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={description => setDescription(description.target.value)}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Description (EN):{' '}
            </p>
            <input
              name="description_en"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={description_en =>
                setDescription_en(description_en.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Image : <br></br>
              (512*512)
            </p>
            <input
              name="item_image_path"
              style={{
                height: '38px',
                width: '330px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              disabled
              value={item_image_path}
            />

            <span className="btn btn-primary btn-file wbt">
              เลือก / แก้ไข
              <input type="file" onChange={handleChangeItem_image_path} />
            </span>

            <button
              type="button"
              className="btn btn-danger wbt"
              onClick={removeImageItem_image_path}
            >
              ลบ
            </button>
          </div>

          <div
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '50%'
            }}
          >
            {stateItem_image_path.previewItem_image_path ? (
              <img
                style={{
                  width: '300px',
                  height: '300px'
                }}
                src={stateItem_image_path.previewItem_image_path}
              />
            ) : item_image_path ? (
              <img
                style={{
                  width: '300px',
                  height: '300px'
                }}
                src={item_image_path}
              />
            ) : null}
          </div>
          {stateItem_image_path.imageURLItem_image_path ? (
            <CropperImage
              image={stateItem_image_path.imageURLItem_image_path}
              handleCancel={handleCancelUploadItem_image_path}
              handleUpload={handleImageUploadItem_image_path}
              scale={1}
            />
          ) : (
            ''
          )}

          <div
            className="form-inline mt-5"
            style={{
              marginBottom: '25px'
            }}
          >
            <div
              style={{
                marginTop: '15px',
                float: 'left',
                width: '20%'
              }}
            ></div>
            <Link to="/MenuManagement">
              <button type="button" className="btn btn-secondary wbt mr-5">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              Save
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
