import React, { useState, useEffect, useRef } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../_metronic/_partials/controls'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../_metronic/_helpers'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { useSubheader } from '../../_metronic/layout'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'
import { baseURL, baseURLDASHBOARD } from '../service/API.js'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Modal from '@material-ui/core/Modal'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Pagination from 'react-pagination-library'
import 'react-pagination-library/build/css/index.css'
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
export const UsersManagement = () => {
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()
  const allData = []
  const [usersManagement, setUsersManagement] = useState([])
  const [openDetailModalData, setOpenDetailModalData] = useState([])
  const [approve, setApprove] = useState(false)
  const [reject, setReject] = useState(false)
  const [id, setId] = useState('')
  const [total, setTotal] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [lastPage, setLastPage] = useState(0)
  const [page, setPage] = useState(1)
  const [openImage, setOpenImage] = useState(false)
  const [imgSelect, setImgSelect] = useState('')
  const [currentPage, setCurrentPage] = useState()
  const [openDetailModal, setOpenDetailModal] = useState(false)

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  suhbeader.setTitle('Users Management')
  const history = useHistory()

  useEffect(() => {
    getUsersManagement(1, 10)
  }, [])

  const getUsersManagement = async (page, perPage) => {
    const apiUrls = `${baseURL}/user?page=${page}&limit=${perPage}${
      user.data.is_admin !== undefined &&
      user.data.is_admin !== '' &&
      user.data.is_admin !== null
        ? '&is_admin=' + user.data.is_admin
        : ''
    }${search !== undefined && search !== '' ? '&search=' + search : ''}`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.data.access_token}`
      }
    })
      .then(async res => {
        await res.data.data.map(async (data, index) => {
          data = {
            id: data.id ? data.id : ' ',
            first_name: data.first_name ? data.first_name : ' ',
            last_name: data.last_name ? data.last_name : ' ',
            email: data.email ? data.email : ' ',
            external_id: data.external_id ? data.external_id : ' ',
            is_admin: data.is_admin,
            enable: data.enable,
            phone_number: data.phone_number ? data.phone_number : ' ',
            username: data.username ? data.username : ' ',
          }
          allData.push(data)
        })
        var number = Number(res.data.total) / Number(res.data.limit)
        setLastPage(Math.ceil(number))
        await setUsersManagement(allData)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getUsersManagementPage = async p => {
    await getUsersManagement(p, perPage)
  }

  const getUsersManagementPerPage = async p => {
    await setPerPage(p)
    await getUsersManagement(page, p)
  }

  const setOpen = (name, id) => {
    console.log(name.target.value)
    if (name.target.value === 'Approve') {
      setApprove(true)
      setId(id)
    } else {
      setReject(true)
      setId(id)
    }
  }

  const setOpenDetail = (name, id, row) => {
    console.log(row)
    setOpenDetailModal(true)
    setOpenDetailModalData(row)
  }

  const columns = [
    {
      dataField: 'first_name',
      text: 'First name',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'last_name',
      text: 'Last name',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'external_id',
      text: 'Shop ID',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'phone_number',
      text: 'Phone number',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'enable',
      text: 'Active',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'is_admin',
      text: 'Admin',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      text: 'Action',
      dataField: 'id',
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              Edit
            </button>
          </div>
        )
      }
    }
  ]

  const editItem = data => {
    history.push('/EditUsersManagement', data)
  }

  const handleClose = () => {
    setApprove(false)
    setReject(false)
    setId('')
    setOpenImage(false)
    setOpenDetailModal(false)
    setImgSelect('')
    setOpenDetailModalData([])
  }

  const [search, setSearch] = useState()
  const handleSearch = () => {
    getUsersManagement(page, perPage)
  }

  const onChanges = e => {
    setPerPage(e.target.value)
    getUsersManagementPerPage(e.target.value)
  }

  const pagination = paginationFactory({
    page: lastPage,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    pageListRenderer: ({ pages, onPageChange }) => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            <Pagination
              currentPage={currentPage}
              totalPages={lastPage}
              changeCurrentPage={async numPage => {
                onPageChange(numPage)
                await setPage(numPage)
                await setCurrentPage(numPage)
                await getUsersManagementPage(numPage)
              }}
              theme="bottom-border form form-control-sm"
            />
          </label>
        </div>
      )
    },
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={e => {
                onChanges(e)
                onSizePerPageChange(e.target.value)
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }
        </label>
      </div>
    )
  })

  return (
    <Card>
      <CardHeader title="Users Management">
        <CardHeaderToolbar>
          <Link to="/CreateUsersManagement">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: '100%' }}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: '100%' }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          keyField="id"
          pagination={pagination}
          data={usersManagement === undefined ? [] : usersManagement}
          columns={columns}
        >
          <PleaseWaitMessage entities={usersManagement} />
          <NoRecordsFoundMessage entities={usersManagement} />
        </BootstrapTable>
      </CardBody>

      <Modal
        open={openImage}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={200}
              defaultPositionY={100}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <TransformComponent>
                    <div className="modal-body">
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          maxWidth: '500px',
                          maxHeight: '500px'
                        }}
                        src={imgSelect}
                      />
                    </div>
                  </TransformComponent>
                  <div className="modal-footer">
                    <div className="tools">
                      <button
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={zoomIn}
                      >
                        +
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={zoomOut}
                      >
                        -
                      </button>
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => handleClose()}
                    >
                      Close
                    </button>
                  </div>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </div>
      </Modal>
    </Card>
  )
}
