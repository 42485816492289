/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../_metronic/_helpers'
import { useSelector } from 'react-redux'

export function AsideMenuList({ layoutProps }) {
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : ''
  }

  const { user } = useSelector(state => state.auth)

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/* Components */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">MANAGE</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}
        <li
          className={`menu-item ${getMenuItemActive('/MenuManagement')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/MenuManagement">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Menu Management</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive('/Category')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Category">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Category</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/Report')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Report">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Report</span>
          </NavLink>
        </li>

        {user.data.is_admin ? (
          <li
            className={`menu-item ${getMenuItemActive('/UsersManagement')}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/UsersManagement">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text">Users Management</span>
            </NavLink>
          </li>
        ) : null}

        {user.data.is_admin ? null : (
          <li
            className={`menu-item ${getMenuItemActive('/OpenTime')}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/OpenTime">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text">Open Time</span>
            </NavLink>
          </li>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
