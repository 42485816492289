import React, { useState, useEffect } from 'react'
import { useSubheader } from '../../../_metronic/layout'
import {
  Card,
  CardBody,
  CardHeader
} from '../../../_metronic/_partials/controls'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { baseURL, imageURL } from '../../service/API.js'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import './component.css'
import { useSelector } from 'react-redux'

export const EditCategory = (props) => {
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()
  suhbeader.setTitle('Edit Category')
  const history = useHistory()
  const [id, setId] = useState()
  const [category_name, setcategory_name] = useState()
  const [category_name_en, setcategory_name_en] = useState()
  const [displayOrder, setDisplayOrder] = useState(0)

  const AlertError = message => {
    Swal.fire('Category!', message, 'error')
  }

  useEffect(() => {
    console.log(props.location.state.row)
    setId(props.location.state.row.id)
    setcategory_name(props.location.state.row.group_name)
    setcategory_name_en(props.location.state.row.group_name_en)
    setDisplayOrder(props.location.state.row.group_order && props.location.state.row.group_order !== " " ? props.location.state.row.group_order : 0)
  }, [])

  const onSubmit = async data => {

    if (category_name === undefined || category_name === '') {
      return AlertError('please insert item name.')
    }

    if (category_name_en === undefined || category_name_en === '') {
      return AlertError('please insert item name en.')
    }
    if (displayOrder === undefined || displayOrder === '' || isNaN(displayOrder) || Number(displayOrder) === 0) {
      return AlertError('please insert display order.')
    }

    var events = {
      shop_id: user.data.shop_id,
      group_name: category_name,
      group_name_en: category_name_en,
      group_order: displayOrder === 0 ? 0 : displayOrder,
    }
    console.log(events)

    let apiUrls = `${baseURL}/category/${id}`
    let token = localStorage.getItem('Token')
    const resultAll = axios({
      method: 'put',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: events
    })
      .then(async res => {
        history.goBack()
      })
      .catch(err => {
        Swal.fire(
          'Edit !',
          'Your can not Edit Category. !! ' + err,
          'error'
        )
      })
  }

  return (
    <>
      <Card>
        <CardHeader title="Edit Category"></CardHeader>
        <CardBody>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Category Name (TH):{' '}
            </p>
            <input
              name="category_name"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={category_name}
              onChange={category_name =>
                setcategory_name(category_name.target.value)
              }
            />
          </div>
          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Category Name (ENG):{' '}
            </p>
            <input
              name="category_name_en"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={category_name_en}
              onChange={category_name_en =>
                setcategory_name_en(category_name_en.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              Display Order:{' '}
            </p>
            <input
              name="displayOrder"
              type="number"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={displayOrder}
              onChange={displayOrder =>
                setDisplayOrder(displayOrder.target.value)
              }
            />
          </div>

          <div
            className="form-inline mt-5"
            style={{
              marginBottom: '25px'
            }}
          >
            <div
              style={{
                marginTop: '15px',
                float: 'left',
                width: '20%'
              }}
            ></div>
            <Link to="/Category">
              <button type="button" className="btn btn-secondary wbt mr-5">
                Cancel
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              Save
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
