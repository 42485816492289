import React, { useState, useEffect, useRef } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../_metronic/_partials/controls'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../_metronic/_helpers'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { useSubheader } from '../../_metronic/layout'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'
import { baseURL, baseURLDASHBOARD } from '../service/API.js'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Modal from '@material-ui/core/Modal'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Pagination from 'react-pagination-library'
import 'react-pagination-library/build/css/index.css'
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
export const MenuManagement = () => {
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()
  const allData = []
  const [menuManagement, setMenuManagement] = useState([])
  const [openDetailModalData, setOpenDetailModalData] = useState([])
  const [approve, setApprove] = useState(false)
  const [reject, setReject] = useState(false)
  const [id, setId] = useState('')
  const [total, setTotal] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [lastPage, setLastPage] = useState(0)
  const [page, setPage] = useState(1)
  const [openImage, setOpenImage] = useState(false)
  const [imgSelect, setImgSelect] = useState('')
  const [currentPage, setCurrentPage] = useState()
  const [openDetailModal, setOpenDetailModal] = useState(false)

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  suhbeader.setTitle('Menu Management')
  const history = useHistory()

  useEffect(() => {
    getMenuManagement(1, 10)
  }, [])

  const getMenuManagement = async (page, perPage) => {
    const apiUrls = `${baseURL}/menuManagement?page=${page}&limit=${perPage}&is_delete=false${
      user.data.shop_id !== undefined &&
      user.data.shop_id !== '' &&
      user.data.shop_id !== null
        ? '&shop_id=' + user.data.shop_id
        : ''
    }${search !== undefined && search !== '' ? '&search=' + search : ''}`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.data.access_token}`
      }
    })
      .then(async res => {
        await res.data.data.map(async (data, index) => {
          data = {
            id: data.id ? data.id : ' ',
            shop_id: data.shop_id ? data.shop_id : ' ',
            name_th: data.name_th ? data.name_th : ' ',
            name_en: data.name_en ? data.name_en : ' ',
            description_th: data.description_th ? data.description_th : ' ',
            description_en: data.description_en ? data.description_en : ' ',
            item_image: data.item_image ? data.item_image : ' ',
            item_group: data.item_group ? data.item_group : ' ',
            price: data.price ? data.price : ' ',
            display_order: data.display_order ? data.display_order : ' ',
            shop_id: data.shop_id ? data.shop_id : ' ',
            enabled: data.enabled,
            is_not_available: data.is_not_available
          }
          allData.push(data)
        })
        var number = Number(res.data.total) / Number(res.data.limit)
        setLastPage(Math.ceil(number))
        await setMenuManagement(allData)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getMenuManagementPage = async p => {
    await getMenuManagement(p, perPage)
  }

  const getMenuManagementPerPage = async p => {
    await setPerPage(p)
    await getMenuManagement(page, p)
  }

  const setOpen = (name, id) => {
    console.log(name.target.value)
    if (name.target.value === 'Approve') {
      setApprove(true)
      setId(id)
    } else {
      setReject(true)
      setId(id)
    }
  }

  const setOpenDetail = (name, id, row) => {
    console.log(row)
    setOpenDetailModal(true)
    setOpenDetailModalData(row)
  }

  const columns = [
    {
      dataField: 'shop_id',
      text: 'Shop ID',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'name_th',
      text: 'Name (TH)',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'name_en',
      text: 'Name (EN)',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'description_th',
      text: 'Description (TH)',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'description_en',
      text: 'Description (EN)',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      text: 'Image',
      dataField: 'image',
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            {row.item_image !== '' ? (
              <a
                onClick={() => {
                  setOpenImage(true)
                  setImgSelect(row.item_image)
                }}
              >
                <img
                  style={{
                    width: '100px',
                    height: '100px'
                  }}
                  src={row.item_image}
                />
              </a>
            ) : (
              ''
            )}
          </div>
        )
      }
    },
    {
      text: 'Category',
      dataField: 'status',
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            {row.item_group === null ? (
              ''
            ) : (
              <select
                as="select"
                className="dropdown"
                defaultValue={row.item_group.id}
                disabled
              >
                <option disabled value={row.item_group.id}>
                  {' '}
                  {row.item_group.group_name}
                </option>
              </select>
            )}
          </div>
        )
      }
    },
    {
      dataField: 'price',
      text: 'Price (Baht)',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      dataField: 'display_order',
      text: 'Display Order',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses
    },
    {
      text: 'Action',
      dataField: 'id',
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItem({ id, row, rowIndex })}
            >
              Edit
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItem({ row, rowIndex })}
            >
              Delete
            </button>
          </div>
        )
      }
    }
  ]

  const editItem = data => {
    history.push('/EditMenuManagement', data)
  }

  const handleClose = () => {
    setApprove(false)
    setReject(false)
    setId('')
    setOpenImage(false)
    setOpenDetailModal(false)
    setImgSelect('')
    setOpenDetailModalData([])
  }

  const [search, setSearch] = useState()
  const handleSearch = () => {
    getMenuManagement(page, perPage)
  }

  const onChanges = e => {
    setPerPage(e.target.value)
    getMenuManagementPerPage(e.target.value)
  }

  const pagination = paginationFactory({
    page: lastPage,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    pageListRenderer: ({ pages, onPageChange }) => {
      return (
        <div className="dataTables_length" id="datatable-basic_length">
          <label>
            <Pagination
              currentPage={currentPage}
              totalPages={lastPage}
              changeCurrentPage={async numPage => {
                onPageChange(numPage)
                await setPage(numPage)
                await setCurrentPage(numPage)
                await getMenuManagementPage(numPage)
              }}
              theme="bottom-border form form-control-sm"
            />
          </label>
        </div>
      )
    },
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={e => {
                onChanges(e)
                onSizePerPageChange(e.target.value)
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }
        </label>
      </div>
    )
  })

  const deleteItem = data => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this Menu Management !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then(result => {
      if (result.value) {
        axios
          .delete(`${baseURL}/menuManagement/${data.row.id}`)
          .then(res => {
            Swal.fire(
              'Deleted!',
              'Your Menu Management has been deleted.',
              'success'
            )
            getMenuManagement(page, perPage)
          })
          .catch(err => {
            Swal.fire(
              'Deleted!',
              'Your Menu Management has been fail to deleted.',
              'error'
            )
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Menu Management is safe :)', 'error')
      }
    })
  }

  return (
    <Card>
      <CardHeader title="Menu Management">
        <CardHeaderToolbar>
          <Link to="/CreateMenuManagement">
            <button type="button" className="btn btn-primary">
              + Create
            </button>
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="row form-inline mb-2">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Text Search"
                style={{ width: '100%' }}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="form-group">
              <button
                type="button"
                id="button"
                className="btn btn-primary"
                style={{ width: '100%' }}
                onClick={() => handleSearch()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <BootstrapTable
          wrapperClasses="table-responsive"
          bordered={false}
          classes="table table-head-custom table-vertical-center overflow-hidden"
          bootstrap4
          keyField="id"
          pagination={pagination}
          data={menuManagement === undefined ? [] : menuManagement}
          columns={columns}
        >
          <PleaseWaitMessage entities={menuManagement} />
          <NoRecordsFoundMessage entities={menuManagement} />
        </BootstrapTable>
      </CardBody>

      <Modal
        open={openImage}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <TransformWrapper
              defaultScale={1}
              defaultPositionX={200}
              defaultPositionY={100}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <TransformComponent>
                    <div className="modal-body">
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          maxWidth: '500px',
                          maxHeight: '500px'
                        }}
                        src={imgSelect}
                      />
                    </div>
                  </TransformComponent>
                  <div className="modal-footer">
                    <div className="tools">
                      <button
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={zoomIn}
                      >
                        +
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary mr-1"
                        onClick={zoomOut}
                      >
                        -
                      </button>
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => handleClose()}
                    >
                      Close
                    </button>
                  </div>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        </div>
      </Modal>
    </Card>
  )
}
