import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../_metronic/_partials/controls'
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../_metronic/_helpers'
import BootstrapTable from 'react-bootstrap-table-next'
import TableHeaderColumn from 'react-bootstrap-table-next'
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit'
import Pagination from '@material-ui/lab/Pagination'
import { useSubheader } from '../../_metronic/layout'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import moment from 'moment'
import { baseURL, baseURLDASHBOARD } from '../service/API.js'
import TextField from '@material-ui/core/TextField'
import { useSelector } from 'react-redux'
import { tr } from 'date-fns/locale'
import { set } from 'object-path'
import * as XLSX from 'xlsx'
import Swal from "sweetalert2";
import Modal from "@material-ui/core/Modal";
import Pagination2 from "@material-ui/lab/Pagination";
import "./style.css";
const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
})

export const Report = () => {
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()

  const [reportData, setReportData] = useState([])
  const [listReport, setListReport] = useState([])
  const [col, setCol] = useState([])
  const [reportData2, setReportData2] = useState([])
  const [dataDetail, setDataDetail] = useState([])
  const [reportValue, setReportValue] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [totalSize, setTotalSize] = useState(100)
  const [rotation, setRotation] = useState(0)
  const [loading, setLoading] = useState(false)
  const [is_loading, setIs_Loading] = useState(false)
  const [openDetailModalTable, setOpenDetailModalTable] = useState(false)
  const [openDetailTableData, setOpenDetailTableData] = useState([])

  const [page3, setPage3] = useState(1);
  const [totalPage3, setTotalPage3] = useState(1);
  const [limit3, setLimit3] = useState(10);
  const [totalSize3, setTotalSize3] = useState(100);
  const [dataDetailIndex, setDataDetailIndex] = useState();
  const [placeholder, setPlaceholder] = useState()

  var d = new Date()
  d.setDate(d.getDate() - 30)
  const [dateFrom, setDateFrom] = useState(moment(d).format('YYYY-MM-DD'))
  const [dateTo, setDateTo] = useState(moment(new Date()).format('YYYY-MM-DD'))
  suhbeader.setTitle('Report')

  useEffect(() => {
    getListReport()
  }, [])

  const getListReport = () => {
    setIs_Loading(true)
    const apiUrls = `${baseURLDASHBOARD}/dashboard/report?page=1&limit=10000`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${user.data.access_token}`
      }
    })
      .then(res => {
        setListReport(res.data.data)
        setIs_Loading(false)

      })
      .catch(err => {
        console.log(err)
      })
  }


  const bindReport = (id, _page, _sizePerPage, search) => {
    var check_detail = false;
    var data = {
      report_id: id,
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }
    const apiUrls = `${baseURLDASHBOARD}/dashboard/report/render/${data.report_id}?page=${_page}&limit=${_sizePerPage}&dateFrom=${data.date_from}&dateTo=${data.date_to}${user.data.shop_id !== undefined && user.data.shop_id !== '' && user.data.shop_id !== null
      ? '&shopId=' + user.data.shop_id
      : ''}
      ${search !== undefined && search !== "" ? "&search=" + search : ""}`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${user.data.access_token}`
      }
    })
      .then(async res => {
        if (res.data.data) {
          var keys = []
          var newState = []
          var array_width = []

          if (res.data.displayColumnWidth) {
            array_width = res.data.displayColumnWidth.split(',');
          }

          keys = Object.keys(res.data.data[0])
          keys.map(async (item,key) => {
            newState.push({
              dataField: item,
              text: item,
              sort: true,
              sortCaret: sortCaret,
              headerSortingClasses,
              formatter: (col) => {
                return (
                  <span
                    style={{ display: 'block', width: array_width[key] ? array_width[key] : 100 }}
                  >
                    {col}
                  </span>
                );
              },
            })
          })

          if (res.data.keyField) {
            newState.push({
              text: "",
              dataField: "id",
              formatter: (id, row, rowIndex) => {
                return (
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => detailItem({ row, rowIndex })}
                    >
                      Detail
              </button>
                  </div>
                );
              },
            })
          }

          var totalpage = Math.ceil(res.data.total / res.data.limit)
          setTotalPage(totalpage)
          setTotalSize(res.data.total)
          setCol(newState)
          setReportData(res.data.data)
          localStorage.setItem("ReportData2", JSON.stringify(res.data.dataKeyField))
          setPlaceholder(res.data.filteredPlaceHolder ? res.data.filteredPlaceHolder : "Text Search")

          setIs_Loading(false)
        } else {
          setIs_Loading(false)

          return Swal.fire(
            "Error !",
            "Report not data",
            "error"
          );
        }


      })
      .catch(err => {
        console.log(err)
      })
  }

  const changeReportDateFrom = (_dateFrom, _page, _sizePerPage) => {
    setDateFrom(_dateFrom)
    setPage(1)
    setSizePerPage(10)
  }

  const changeReportDateTo = (_dateTo, _page, _sizePerPage) => {
    setDateTo(_dateTo)
    setPage(1)
    setSizePerPage(10)
  }

  function imageFormatter(cell, row) {
    return (
      <input
        type="image"
        src={cell}
        alt="priv-image"
        width="48"
        height="48"
      ></input>
    )
  }

  const [search3, setSearch3] = useState();
  const handleSearch3 = () => {
    detailSet(reportValue, 1, 10, search3)
    setPage3(1);
    setLimit3(10)
  };


  const handleSearch = (reportValue, search) => {

    setIs_Loading(true)

    if (!reportValue) {
      setIs_Loading(false)
      return Swal.fire(
        "Error !",
        "Please Select Report",
        "error"
      );
    }

    if (!dateFrom) {
      setIs_Loading(false)
      return Swal.fire(
        "Error !",
        "Please Select Date From",
        "error"
      );
    }

    if (!dateTo) {
      setIs_Loading(false)
      return Swal.fire(
        "Error !",
        "Please Select Date To",
        "error"
      );
    }
    var check_detail = false;

    setReportData([])
    var data = {
      report_id: reportValue,
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }

    const apiUrls = `${baseURLDASHBOARD}/dashboard/report/render/${reportValue}?page=${page}&limit=${sizePerPage}&dateFrom=${data.date_from}&dateTo=${data.date_to}${user.data.shop_id !== undefined && user.data.shop_id !== '' && user.data.shop_id !== null
      ? '&shopId=' + user.data.shop_id
      : ''}${search !== undefined && search !== "" ? "&search=" + search : ""}`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${user.data.access_token}`
      }
    })
      .then(async res => {
        if (res.data.data) {
          var keys = []
          var newState = []
          var array_width = []

          if (res.data.displayColumnWidth) {
            array_width = res.data.displayColumnWidth.split(',');
          }
          keys = Object.keys(res.data.data[0])
          keys.map(async (item,key) => {
            newState.push({
              dataField: item,
              text: item,
              sort: true,
              sortCaret: sortCaret,
              headerSortingClasses,
              formatter: (col) => {
                return (
                  <span
                    style={{ display: 'block', width: array_width[key] ? array_width[key] : 100 }}
                  >
                    {col}
                  </span>
                );
              },
            })
          })

          if (res.data.keyField) {
            newState.push({
              text: "",
              dataField: "id",
              formatter: (id, row, rowIndex) => {
                return (
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => detailItem({ row, rowIndex })}
                    >
                      Detail
              </button>
                  </div>
                );
              },
            })
          }

          var totalpage = Math.ceil(res.data.total / res.data.limit)
          setTotalPage(totalpage)
          setTotalSize(res.data.total)
          setCol(newState)
          setReportData(res.data.data)
          localStorage.setItem("ReportData2", JSON.stringify(res.data.dataKeyField))
          setPlaceholder(res.data.filteredPlaceHolder ? res.data.filteredPlaceHolder : "Text Search")

          setIs_Loading(false)
        } else {
          setIs_Loading(false)
          return Swal.fire(
            "Error !",
            "Report not data",
            "error"
          );
        }
      })
      .catch(err => {
        setIs_Loading(false)
        return Swal.fire(
          "Error !",
          "Report not data",
          "error"
        );
      })

  };

  const [search, setSearch] = useState();


  const handlePageChange = (event, value) => {
    setPage(value)
    setIs_Loading(true)
    bindReport(reportValue, value, sizePerPage, search)
  }

  const handlePageSizeChange = event => {
    setSizePerPage(event.target.value)
    setPage(1)
    setIs_Loading(true)
    bindReport(reportValue, 1, event.target.value, search)
  }

  const detailItem = (data) => {
    setOpenDetailTableData([])
    setDataDetail([]);
    setDataDetailIndex(data)
    let get = localStorage.getItem("ReportData2")
    const cover_cam = JSON.parse(get)
    var id_item = {}

    cover_cam.map((datas, key) => {
      if (data.rowIndex === key) {
        id_item = datas
      }
    })

    if (id_item) {
      const apiUrls = `${baseURLDASHBOARD}/dashboard/report/renderDetail/${reportValue}?page=${page3}&limit=${limit3}&dateFrom=${dateFrom}&dateTo=${dateTo}&id=${id_item.id}`
      const resultAll = axios({
        method: 'get',
        url: apiUrls,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${user.data.access_token}`
        }
      })
        .then(async res => {
          // console.log(res.data)
          if (res.data.data.length > 0) {
            var keys = []
            var newState = []
            keys = Object.keys(res.data.data[0])
            keys.map(async key => {
              newState.push({
                dataField: key,
                text: key,
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses
              })
            })
            setOpenDetailTableData(newState)
            setDataDetail(res.data.data);
            setOpenDetailModalTable(true);

            var totalpage = Math.ceil(res.data.total / res.data.limit)
            setTotalPage3(totalpage)
            setTotalSize3(res.data.total)
            setLimit3(limit3)

          } else {
            setOpenDetailModalTable(true);
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

  };

  const detailSet = (reportValue, page3, limit3, search3) => {
    let get = localStorage.getItem("ReportData2")
    const cover_cam = JSON.parse(get)
    var id_item = {}

    cover_cam.map((datas, key) => {
      if (dataDetailIndex.rowIndex === key) {
        id_item = datas
      }
    })

    if (id_item) {
      const apiUrls = `${baseURLDASHBOARD}/dashboard/report/renderDetail/${reportValue}?page=${page3}&limit=${limit3}&dateFrom=${dateFrom}&dateTo=${dateTo}&id=${id_item.id}${search3 ? '&search=' + search3 : ''}`
      const resultAll = axios({
        method: 'get',
        url: apiUrls,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${user.data.access_token}`
        }
      })
        .then(async res => {
          console.log(res)

          if (res.data.data.length > 0) {
            var keys = []
            var newState = []
            keys = Object.keys(res.data.data[0])
            keys.map(async key => {
              newState.push({
                dataField: key,
                text: key,
                sort: true,
                sortCaret: sortCaret,
                headerSortingClasses
              })
            })
            setDataDetail(res.data.data);
            var totalpage = Math.ceil(res.data.total / res.data.limit)
            setTotalPage3(totalpage)
            setTotalSize3(res.data.total)
            setLimit3(limit3)
          } else {
            return Swal.fire(
              "Error !",
              "Detail not data",
              "error"
            );
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

  };


  const handleClose = () => {
    setOpenDetailModalTable(false);
  };

  const handlePageChange3 = (event, value) => {
    setPage3(value);
    detailSet(reportValue, value, limit3)
  };

  const handlePageSizeChange3 = (event) => {
    setLimit3(event.target.value);
    setPage(1)
    detailSet(reportValue, 1, event.target.value)
  };



  const exportExcel = () => {
    setLoading(true)
    const headers = []
    const rows = []
    col.map(c => {
      headers.push(c.dataField)
    })

    var data = {
      report_id: reportValue,
      date_from: moment(dateFrom).format('YYYY-MM-DD'),
      date_to: moment(dateTo).format('YYYY-MM-DD')
    }
    const apiUrls = `${baseURLDASHBOARD}/dashboard/report/render/${data.report_id}?page=1&limit=${totalSize}&dateFrom=${data.date_from}&dateTo=${data.date_to}`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${user.data.access_token} `
      }
    })
      .then(async res => {
        var _data = res.data.data
        var key = []

        _data.map((item, num) => {
          try {
            if (item['created_at']) {
              item['created_at'] = moment(item['created_at']).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            }
          } catch (err) { }

          const alphaNumOut = Object.keys(item).map(key => item[key])

          rows.push(alphaNumOut)
        })
        const datas = [headers, ...rows]

        const reportTitle = listReport.find(
          d => Number(d.id) === Number(reportValue)
        ).title

        const fileName = `export-slash-${reportTitle}.xlsx`
        const ws = (XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([[]]))
        const wb = (XLSX.WorkBook = XLSX.utils.book_new())

        XLSX.utils.sheet_add_aoa(ws, datas)
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
        XLSX.writeFile(wb, fileName)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <Card>
      <CardHeader title="Report">
      </CardHeader>
      <CardBody>
        <div className="d-flex justify-content-between">

          <select
            as="select"
            className="mb-3"
            style={{
              width: '305px',
              height: '38px',
            }}
            defaultValue={0}
            onChange={even => {
              setReportValue(even.target.value)
              setPage(1)
              setSizePerPage(10)
            }
            }
          >
            <option disabled value={0}>
              {' '}
              Please Select ...
            </option>
            {listReport.map((data, index) => {
              return (
                <option key={index} value={data.id}>
                  {data.title}
                </option>
              )
            })}
          </select>

        </div>
        <div class="d-flex justify-content-between mt-1">
          <div>
            Search :
            <input
              type="text"
              className="mr-2 ml-1"
              placeholder={placeholder ? placeholder : "Text Search"}
              label=" "
              style={{
                height: '38px',
                width: '250px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              onChange={(e) => setSearch(e.target.value)}
            />

          </div>

          <div>

            <TextField
              id="DateFrom"
              type="date"
              label="Date From"
              defaultValue={dateFrom}
              style={{
                height: '38px',
                width: '185px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              InputLabelProps={{
                shrink: true
              }}
              onChange={name =>
                changeReportDateFrom(name.target.value, page, sizePerPage)
              }
            />
            <TextField
              id="DateFrom"
              type="date"
              label="Date To"
              defaultValue={dateTo}
              style={{
                height: '38px',
                width: '185px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              InputLabelProps={{
                shrink: true
              }}
              onChange={name =>
                changeReportDateTo(name.target.value, page, sizePerPage)
              }
            />
            <button
              type="button"
              id="button"
              className="btn btn-primary mt-1 mb-3 ml-3"
              onClick={() => handleSearch(reportValue, search)}
            >
              Search
</button>
          </div>
        </div>
        {
          is_loading ? <div className="row">
            <div style={{
              position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
            }}>

              <div className="spinner-grow text-primary spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-secondary spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-success spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-danger spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-warning spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-info spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-light spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-dark spinner-grow-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
            : null
        }

        {Number(reportValue) !== 0 && reportData.length > 0 ? (
          <>
            <ToolkitProvider keyField={reportValue} exportCSV>
              {props => (
                <div>
                  {loading === true ? (
                    <button
                      style={{ width: '150px' }}
                      className="btn btn-primary"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="button"
                      id="button"
                      className="btn btn-primary"
                      style={{ width: '150px' }}
                      onClick={() => exportExcel()}
                      disabled={is_loading}
                    >
                      Export CSV!!
                    </button>
                  )}

                  <BootstrapTable
                    {...props.baseProps}
                    data={reportData}
                    columns={col}
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                  />

                  <div className=" col-lg-12">
                    {totalPage > 1 ? (
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mt-3">
                            {'Items per Page: '}
                            <select
                              onChange={handlePageSizeChange}
                              value={sizePerPage}
                              disabled={is_loading}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <Pagination
                            disabled={is_loading}
                            className="mt-3 "
                            count={totalPage}
                            page={page}
                            siblingCount={1}
                            boundaryCount={1}
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <span className="react-bootstrap-table-pagination-total">
                    Showing {page === 1 ? 1 : sizePerPage * (page - 1) + 1} to{' '}
                    {page === 1
                      ? sizePerPage
                      : page === totalPage
                      ? totalSize
                      : sizePerPage * page}{' '}
                    of {totalSize} Results
                  </span> */}
                </div>
              )}
            </ToolkitProvider>
          </>
        ) : (
          ''
        )}




        <Modal
          open={openDetailModalTable}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="justify-content-center d-flex align-items-center mt-1">
                <span style={{ fontSize: "30px" }}></span>
              </div>
              <div className="modal-body my_scroll_divs">
                {/* <Form className="col"> */}
                {openDetailTableData.length > 0 ?
                  <>
                    <div className="row form-inline mb-2">
                      <div className="col-lg-4 col-md-4 col-sm-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Text Search"
                            style={{ width: "100%" }}
                            onChange={(e) => setSearch3(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2">
                        <div className="form-group">
                          <button
                            type="button"
                            id="button"
                            className="btn btn-primary"
                            style={{ width: "100%" }}
                            onClick={() => handleSearch3()}
                          >
                            Search
                    </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <BootstrapTable
                        keyField="id"
                        data={dataDetail}
                        columns={openDetailTableData}
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                      />
                      <div className=" col-lg-12">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mt-3">
                              {"Items per Page: "}
                              <select onChange={handlePageSizeChange3} value={limit3}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <Pagination2
                              className="mt-3 "
                              count={totalPage3}
                              page={page3}
                              siblingCount={1}
                              boundaryCount={1}
                              variant="outlined"
                              shape="rounded"
                              onChange={handlePageChange3}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                  : <h1>No data</h1>}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleClose()}
                  >
                    Close
                </button>
                </div>
                {/* </Form> */}
              </div>
            </div>
          </div>
        </Modal>



      </CardBody>
    </Card>
  )
}
