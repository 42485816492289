import React, { Suspense, lazy } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import { useSelector } from 'react-redux'
import { DashboardPage } from './pages/DashboardPage'
import { MenuManagement } from './pages/MenuManagement'
import { CreateMenuManagement } from './pages/component/CreateMenuManagement'
import { EditMenuManagement } from './pages/component/EditMenuManagement'

import { UsersManagement } from './pages/UsersManagement'
import { CreateUsersManagement } from './pages/component/CreateUsersManagement'
import { EditUsersManagement } from './pages/component/EditUsersManagement'

import { Category } from './pages/Category'
import { CreateCategory } from './pages/component/CreateCategory'
import { EditCategory } from './pages/component/EditCategory'
import { Report } from './pages/Report'
import { OpenTime } from './pages/OpenTime'

export default function BasePage() {
  const { user } = useSelector(state => state.auth)

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/Dashboard" component={DashboardPage} />

        <ContentRoute path="/MenuManagement" component={MenuManagement} />
        <ContentRoute
          path="/CreateMenuManagement"
          component={CreateMenuManagement}
        />
        <ContentRoute
          path="/EditMenuManagement"
          component={EditMenuManagement}
        />
        <ContentRoute path="/Category" component={Category} />
        <ContentRoute path="/CreateCategory" component={CreateCategory} />
        <ContentRoute path="/EditCategory" component={EditCategory} />
        <ContentRoute path="/Report" component={Report} />
        <ContentRoute path="/OpenTime" component={OpenTime} />
        {user.data.is_admin ? (
          <>
            <ContentRoute path="/UsersManagement" component={UsersManagement} />
            <ContentRoute
              path="/CreateUsersManagement"
              component={CreateUsersManagement}
            />
            <ContentRoute
              path="/EditUsersManagement"
              component={EditUsersManagement}
            />
          </>
        ) : null}

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  )
}
