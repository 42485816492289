import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from '../../_metronic/_partials/controls'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from '../../_metronic/_helpers'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { useSubheader } from '../../_metronic/layout'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'
import { baseURL, baseURLDASHBOARD } from '../service/API.js'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Modal from '@material-ui/core/Modal'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import Pagination from 'react-pagination-library'
import 'react-pagination-library/build/css/index.css'
import { useSelector } from 'react-redux'
export const OpenTime = () => {
  const { user } = useSelector(state => state.auth)
  const suhbeader = useSubheader()

  const [open, setOpen] = useState()
  const [close, setClose] = useState()

  const [open_backup, setOpenBackUp] = useState()
  const [close_backup, setCloseBackUp] = useState()

  suhbeader.setTitle('Open Time')
  const history = useHistory()

  const AlertError = message => {
    Swal.fire('Open time!', message, 'error')
  }

  useEffect(() => {
    getOpenTime(1, 10)
  }, [])

  const getOpenTime = async () => {
    var shop_id = user.data.shop_id
    const apiUrls = `${baseURL}/shop_slash${
      shop_id !== undefined && shop_id !== '' ? '?id=' + shop_id : ''
    }`
    const resultAll = axios({
      method: 'get',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.data.access_token}`
      }
    })
      .then(async res => {
        let open_date = convert_time(res.data.data.open)
        let close_date = convert_time(res.data.data.close)

        setOpenBackUp(open_date)
        setCloseBackUp(close_date)

        setOpen(open_date)
        setClose(close_date)

        console.log(open_date)
        console.log(close_date)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const convert_time = string_time => {
    let newDate = new Date()
    let cover_dates = convert_date(newDate)
    let newDateTime = new Date(cover_dates + string_time)
    let newDateTimes = moment(newDateTime).format('HH:mm:ss')

    return newDateTimes
  }

  const convert_date = date => {
    var month = (date.getMonth() < 10 ? '0' : '') + date.getMonth()
    var day = (date.getDate() < 10 ? '0' : '') + date.getDate()
    var year = date.getFullYear()

    return `${year}-${month}-${day}T`
  }

  const onSubmit = async data => {
    if (open === undefined || open === '') {
      return AlertError('please insert item name.')
    }

    if (close === undefined || close === '') {
      return AlertError('please insert item name en.')
    }

    var events = {
      open: open,
      close: close
    }

    let apiUrls = `${baseURL}/shop_slash/${user.data.shop_id}`
    let token = localStorage.getItem('Token')
    const resultAll = axios({
      method: 'put',
      url: apiUrls,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: events
    })
      .then(async res => {

        let open_date = convert_time(open)
        let close_date = convert_time(close)

        setOpenBackUp(open_date)
        setCloseBackUp(close_date)

        setOpen(open_date)
        setClose(close_date)

        Swal.fire('Update Open Time!', 'Your data has been update.', 'success')
      })
      .catch(err => {
        Swal.fire('Edit !', 'Your can not Edit Opentime. !! ' + err, 'error')
      })
  }

  return (
    <Card>
      <CardHeader title="Open Time"></CardHeader>
      <CardBody>
        <div className="form-inline" style={{ marginBottom: '25px' }}>
          <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
            Open : (EX 00:01){' '}
          </p>
          <input
            className="form-control"
            type="time"
            value={open}
            onChange={e => setOpen(e.target.value)}
            id="example-time-input"
            style={{
              height: '38px',
              width: '230px',
              backgroundColor: '#F4F6F9',
              borderRadius: '5px',
              borderWidth: '0'
            }}
          />
        </div>

        <div className="form-inline" style={{ marginBottom: '25px' }}>
          <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
            Close : (EX 23:59){' '}
          </p>

          <input
            className="form-control"
            type="time"
            value={close}
            onChange={e => setClose(e.target.value)}
            id="example-time-input"
            style={{
              height: '38px',
              width: '230px',
              backgroundColor: '#F4F6F9',
              borderRadius: '5px',
              borderWidth: '0'
            }}
          />
        </div>

        <div
          className="form-inline mt-5"
          style={{
            marginBottom: '25px'
          }}
        >
          <div
            style={{
              marginTop: '15px',
              float: 'left',
              width: '20%'
            }}
          ></div>
          {open !== open_backup || close !== close_backup ? (
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              Save
            </button>
          ) : (
            <button
              disabled
              className="btn btn-primary"
            >
              Save
            </button>
          )}
        </div>
      </CardBody>
    </Card>
  )
}
